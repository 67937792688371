import ArrowBack from "@/presentation/components/common/ArrowBack";
import {PATHS} from "@/presentation/components/heatCalculationPage/components/MKDPage/const";
import HoveredPopover from "@/presentation/components/common/HoveredPopover";
import {NavLink, Route, Routes} from "react-router-dom";
import ApartmentForm from "@/presentation/components/heatCalculationPage/components/MKDPage/components/ApartmentForm/ApartmentForm";
import {newApartmentFormSelectors} from "@/internal/lib/storeModels/models/mkds/forms/mkds_forms";
import React, {useEffect, useRef, useState} from "react";
import {apartmentsEndpoint} from "@/data/api_entities/apartments/apartments_endpoint";
import {mkdsStateSelectors} from "@/internal/lib/storeModels/models/mkds/mkdsState";
import {useNavigateBack} from "@/internal/custom_hooks/navigateBack";
import {mkdsEndpoint} from "@/data/api_entities/mkds/mkds_endpoint";
import Loader from "@/presentation/components/loader/Loader";
import LinkTab from "@/presentation/components/common/LinkTab";
import {MKDHelper} from "@/presentation/components/heatCalculationPage/helpers";
import ApartmentPage from "@/presentation/components/heatCalculationPage/components/MKDPage/components/ApartmentPage";
import {selectors} from "@/presentation/shared/ui/message";
import {FormattedMessage} from "react-intl";
import {extractNumber} from "@/internal/helpers/helpers";
import useWindowSize from "@/internal/custom_hooks/windowResizeHook";

const ApartmentsPage = ({selectedObject}) => {
  const [isCreating, setIsCreating] = useState(false)
  const MKDId = mkdsStateSelectors.selectedMKD.useValue()
  const [navigateBack] = useNavigateBack()
  const resetForm = newApartmentFormSelectors.useResetForm()
  const [loadingList, setLoadingList] = useState(false)
  const [list, setList] = mkdsStateSelectors.apartmentsList.useState()
  const [paginationParams, setPaginationParams] = useState(null)
  const [isUploadNext, setIsUploadNExt] = useState(false)
  const setMessage = selectors.useSetMessage()
  const [searchQuery, setSearchQuery] = useState('')
  const lastSearchRequest = useRef(null)
  const searchTimeout = useRef(null)
  const [findList, setFindList, resetFindList] = mkdsStateSelectors.findApartmentsList.useState()
  const [lastApartment, setLastApartment] = useState(0)
  const windowSize = useWindowSize()
  const listLoaderRef = useRef(null)

  useEffect(() => {
    return () => {
      resetForm()
      resetFindList()

      lastSearchRequest.current?.abort()
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current)
      }
    }
  }, [])

  useEffect(() => {
    const wrapper = listLoaderRef.current?.wrapper.current
    if (wrapper) {
      const hasVerticalScroll = wrapper.scrollHeight > wrapper.clientHeight

      if (!hasVerticalScroll && paginationParams) {
        if (isUploadNext || searchQuery.length) return
        uploadNextPage()
      }
    }
  }, [windowSize, list, paginationParams])

  useEffect(() => {
    if (!selectedObject?.id) return

    setLoadingList(true)

    const {promise} = mkdsEndpoint.getApartments(selectedObject?.id, 1)

    promise
      .then(res => {
        if (Array.isArray(res?.results)) {
          setList(res.results)
          setLastApartment(extractNumber(res.last_kv) ?? 0)
          if (res.current_page < res.total_pages) {
            setPaginationParams({
              nextPage: 2,
              totalPages: res.total_pages
            })
          }
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось загрузить данные квартиры',
            type: 'error',
            count: 3000
          })
          console.log(res)
        }
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось загрузить данные квартиры',
          type: 'error',
          count: 3000
        })
        console.log(err)
      })
      .finally(() => setLoadingList(false))
  }, [selectedObject?.id])

  const uploadNextPage = () => {
    if (!paginationParams) return
    setIsUploadNExt(true)

    const {promise} = mkdsEndpoint.getApartments(selectedObject?.id, paginationParams.nextPage)
    promise
      .then(res => {
        if (Array.isArray(res?.results)) {
          const newList = []
          res.results.forEach(apartment => {
            const preexistElem = list.find(item => item.id == apartment.id)

            if (preexistElem) return
            newList.push(apartment)
          })

          setList([...list, ...newList])
          if (res.current_page < res.total_pages) {
            setPaginationParams(prev => ({
              nextPage: prev.nextPage + 1,
              totalPages: res.total_pages
            }))
          } else {
            setPaginationParams(null)
          }
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось загрузить список квартир',
            type: 'error',
            count: 3000
          })
          console.log(res)
        }
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось загрузить список квартир',
          type: 'error',
          count: 3000
        })
        console.log(err)
      })
      .finally(() => setIsUploadNExt(false))
  }

  const onCreateNewApartment = (data) => {
    setIsCreating(true)

    const {promise} = apartmentsEndpoint.create({...data, mansions: MKDId})

    promise
      .then(res => {
        if ('id' in res) {
          setLastApartment((extractNumber(data.kv) ?? 0))
          setList([res, ...list])
          navigateBack()
          resetForm()
          setMessage({
            header: 'Добавление квартиры',
            message: 'Квартира успешно добавлена',
            type: 'success',
            count: 3000
          })
        } else if (res?.detail === 'double apartment') {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Квартира с данным номером уже существует',
            type: 'error',
            count: 3000
          })
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось добавить квартиру',
            type: 'error',
            count: 3000
          })
          console.log(res)
        }
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось добавить квартиру',
          type: 'error',
          count: 3000
        })
        console.log(err)
      })
      .finally(() => setIsCreating(false))
  }

  const handleScrollList = (event) => {
    if (isUploadNext || searchQuery.length) return
    const wrapper = event.target

    if (wrapper.scrollTop + wrapper.clientHeight >= wrapper.scrollHeight * 0.99) {
      uploadNextPage()
    }
  }

  const onSearch = (e) => {
    setSearchQuery(e.target.value)
    const value = e.target.value.trim()
    lastSearchRequest.current?.abort()
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current)
    }

    if (value.length) {
      const timeout = setTimeout(() => {
        const {promise, controller} = mkdsEndpoint.searchApartments(MKDId, value.toString())
        lastSearchRequest.current = controller
        promise
          .then(res => {
            if (Array.isArray(res?.results)) {
              setFindList(res.results)
            }

            lastSearchRequest.current = null
            searchTimeout.current = null
          })
          .catch((err) => {
            if (err.name === 'AbortError') return
            lastSearchRequest.current = null
            searchTimeout.current = null
          })
      }, 100)

      searchTimeout.current = timeout
    } else {
      setFindList(null)
    }

  }

  return (
    <>
      <div className={'apartments-page'}>
        <ArrowBack
          pathOnVisible={PATHS.apartments}
        />

        <div className="apartments-page__title-wrapper">
          <h2 className="title">Квартиры</h2>

          <HoveredPopover text={'Добавить квартиру'}>
            <NavLink
              className={`btn btn_transparent-bg`}
              to={PATHS.newApartment}
            >
              +
            </NavLink>
          </HoveredPopover>
        </div>

        <div className="apartments-page__search">
          <div className="form-group">
            <label>
              Поиск квартиры
            </label>

            <input
              value={searchQuery}
              onChange={onSearch}
            />
          </div>
        </div>

        <Loader
          loading={loadingList}
          styles={{
            wrapper: (base) => ({
              ...base,
              width: '100%',
              overflow: 'auto',
              minHeight: '100px'
            }),
          }}
          onScrollWrapper={handleScrollList}
          propRef={listLoaderRef}
        >
          {((findList ?? list).length)
            ? (findList ?? list).map(apartment => {
              return <LinkTab
                key={apartment.id}
                path={apartment?.id?.toString()}
                text={MKDHelper.getAddressWithApartments(selectedObject, apartment.kv)}
              />
            })
            : <p className={'apartments-page__empty-list'}>
              {
                findList !== null && !findList.length
                  ? 'Ничего не найдено'
                  : ' Список пуст. Вы можете добавить квартиру нажав на +'
              }
          </p>
          }
        </Loader>
      </div>

      <Routes>
        <Route
          path={PATHS.newApartment}
          element={<ApartmentForm
            lastApartment={lastApartment}
            isLoading={isCreating}
            formSelectors={newApartmentFormSelectors}
            pathOnVisible={PATHS.newApartment}
            onValidSubmit={onCreateNewApartment}
          />}
        />

        <Route
          path={':id/*'}
          element={<ApartmentPage selectedObject={selectedObject} />}
        />
      </Routes>
    </>
  )
}

export default ApartmentsPage
