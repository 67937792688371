import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {emailValidator, requiredValidator} from "@/internal/validators/validationsRules";
import {EMAIL_VALIDATION_MSG, requiredMsg} from "@/internal/validators/validationsMessages";

const newOwnerFormController = new FormModelFabric({
  initialData: {
    email: '',
    login: ''
  },
  initialValidations: {
    login: [],
    fullName: []
  },
  getValidators: () => {
    return {
      email: (value) => {
        return emailValidator(value)
          ? []
          : [EMAIL_VALIDATION_MSG]
      },
      login: (value) => {
        return requiredValidator(value)
          ? []
          : [requiredMsg]
      }
    }
  }
})

export const newOwnerFormControllerSelectors = newOwnerFormController.createSelectors()