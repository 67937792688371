import {useNavigate} from "react-router-dom";
import icons from "@/assets/svg/icons/icons.svg";
import {MKDHelper} from "@/presentation/components/heatCalculationPage/helpers";
import React from "react";

const OwnerMkdCard = ({data}) => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(`${data.id}`)
  }

  return (
    <div className={'mkd-card'} onClick={handleClick}>
      <div className="mkd-card__body">
        <div className="mkd-card__img">
          <svg><use href={`${icons}#mansions`}/></svg>
        </div>

        <div className="mkd-card__address">
          <span>{MKDHelper.getAddress(data)}</span>
          <span>{MKDHelper.getRegion(data)}</span>
        </div>
      </div>
    </div>
  )
}

export default OwnerMkdCard;
