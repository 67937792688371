import Loader from "@/presentation/components/loader/Loader";
import {PageReturn} from "@/assets/svg";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {FormattedMessage} from "react-intl";
import DadataSelect from "@/presentation/components/addressForm/DadataSelect";
import SelectInput from "@/presentation/components/common/formComponents/SelectInput";
import {userDataSelectors} from "@/internal/lib/storeModels/models/user/userDataModel";
import {servicemenEndpoints} from "@/data/api_entities/servicemen/endpoints";
import Input from "@/presentation/components/common/formComponents/Input";
import {mkdsStateSelectors} from "@/internal/lib/storeModels/models/mkds/mkdsState";
import DefaultModal from "@/presentation/components/common/modals/DefaultModal";
import {mkdsEndpoint} from "@/data/api_entities/mkds/mkds_endpoint";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import {UNIT_TYPES} from "@/presentation/components/heatCalculationPage/const";
import {selectors} from "@/presentation/shared/ui/message";
import {emulateTab} from "emulate-tab";
import Select from "@/presentation/components/common/selects/Select";

const timeZonesOptions = (() => {
  const length = 25;
  const options = []

  for (let i = 0; i < length; i++) {
      options.push({
        value: (i - 12).toString(),
        label: (i - 12).toString()
      })
  }

  return options
})()

const MKDForm = (
  {
    isOpenDeleteModal = false,
    toggleDeleteModal,
    onDelete,
    isDeleting = false,
    hideAside = null,
    formStateSelectors,
    isLoading = false,
    onValidSubmit,
    isNew = false,
    editId = null,
    classPostfix = '',
    pathOnVisible = null,
  }) => {
  const userData = userDataSelectors.userData.useValue()
  const isOpenAside = mkdsStateSelectors.sideForm.useValue()
  const dataId = mkdsStateSelectors.selectedMKDSettingsId.useValue()
  const [devicesTypes, setDevicesTypes] = useState([])

  const [lastGetServiceManagersRequest, setLastGetServiceManagersRequest] = useState(null)
  const [serviceManagers, setServiceManagers] = useState([])
  const setFormValue = (key) => formStateSelectors.setFormDataValue(key)
  const setOnValidSubmit = formStateSelectors.useSetOnValidSubmit()
  const serviceManager = formStateSelectors.useFormDataValue('servicemanager')
  const region = formStateSelectors.useFormDataValue('region')
  const autoReport = formStateSelectors.useFormDataValue('auto_report')
  const isValidForm = formStateSelectors.useIsFormValid()
  const onSubmitForm = formStateSelectors.useOnClickSubmit()
  const setInitialData = formStateSelectors.useSetInitFormData()
  const [lastLoadingDataRequest, setLastLoadingDataRequest] = useState(null)
  const [firstLoading, setFirstLoading] = useState(() => true)
  const setMessage = selectors.useSetMessage()

  const request = useRef(null)

  const loading = useMemo(() => {
    return isNew
      ? isLoading
      : firstLoading || isLoading || !!lastLoadingDataRequest
  }, [isNew, firstLoading, lastLoadingDataRequest, isLoading])

  useEffect(() => {
    const id = editId ?? dataId
    if (isNew || !id) return

    if (lastLoadingDataRequest) {
      lastLoadingDataRequest.abort()
    }

    const {promise, controller} = mkdsEndpoint.getById(id)
    setLastLoadingDataRequest(controller)
    promise
      .then(res => {
        console.log(res)
        setInitialData({...res, timezone: res.timezone ?? '0'})
        setLastLoadingDataRequest(null)
      })
      .catch(err => {
        if (err.name === 'AbortError') return
        setLastLoadingDataRequest(null)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось загрузить данные МКД',
          type: 'error',
          count: 3000
        })
      })
      .finally(() => {
        if (firstLoading) setFirstLoading(false)
      })
  }, [isNew, dataId])

  useEffect(() => {
    setOnValidSubmit(onValidSubmit)
  }, [onValidSubmit])

  useEffect(() => {
    request.current?.abort()

    const {promise, controller} = mkdsEndpoint.getDevicesTypes()
    request.current = controller

    promise
      .then(res => {
        if (Array.isArray(res?.results)) {
          setDevicesTypes(res.results.map(item => ({
            value: item,
            label: item.title
          })))
        }
        request.current = null
      })
      .catch(err => {
        if (err.name === 'AbortError') return
        console.log(err)
        request.current = null
      })
  }, [])

  useEffect(() => {
    if (userData.service_manager || loading) return
    setServiceManagers([])
    if (lastGetServiceManagersRequest) {
      lastGetServiceManagersRequest.abort()
    }

    const {promise, controller} = servicemenEndpoints.getServicemensByRegion(region)

    setLastGetServiceManagersRequest(controller)

    promise.then(res => {
      if (Array.isArray(res)) {
        setServiceManagers(res.map(serviceManger => ({
          value: serviceManger.id,
          label: serviceManger.user
        })))
        const index = res.findIndex(elem => elem.id === serviceManager)
        if (index < 0) {
          setFormValue('servicemanager')(0)
        }
      }

      setLastGetServiceManagersRequest(null)
    })
      .catch(err => {
        if (err.name === 'AbortError') return

        setLastGetServiceManagersRequest(null)
      })
  }, [region, loading])

  const handleSubmit = () => {
    if (!isValidForm) return

    onSubmitForm()
  }

  const onPressEnter = (e) => {
    if (e.code !== 'Enter') return

    e.stopPropagation()
    emulateTab()
  }

  return (
    <div className={`mkd-from ${classPostfix}`} onKeyDown={onPressEnter}>

      {!!pathOnVisible &&
        <ArrowBack
          pathOnVisible={pathOnVisible}
          withId={false}
        />
      }

      <Loader loading={loading}>
        <div className="mkd-from__title-wrapper">
          {!!hideAside &&
            <div
              className="mkd-from__back-img"
              onClick={hideAside}
            >
              <img
                src={PageReturn}
                alt="PageReturn"
              />
            </div>
          }

          <h2 className={'title mkd-from__title'}>
            {isNew
              ? 'Добавление МКД'
              : 'Параметры МКД'
            }
          </h2>
        </div>

        <div className="form-fields">
          <DadataSelect
            label={'Найти адрес'}
            addressFormSelectors={formStateSelectors}
            placeholder={'Введите адрес'}
            clearNameOnChange={isOpenAside}
          />

          <DadataSelect
            name={'region'}
            label={<FormattedMessage id={'addressForm.region'}/>}
            addressFormSelectors={formStateSelectors}
          />

          <DadataSelect
            name='city'
            label={<FormattedMessage id={'addressForm.city'}/>}
            startQueryFormNames={['region']}
            addressFormSelectors={formStateSelectors}
          />

          <DadataSelect
            name={'street'}
            label={<FormattedMessage id={'addressForm.street'}/>}
            addressFormSelectors={formStateSelectors}
            startQueryFormNames={['region', 'city']}
          />

          <DadataSelect
            name={'building'}
            label={<FormattedMessage id={'addressForm.building'}/>}
            addressFormSelectors={formStateSelectors}
            startQueryFormNames={['region', 'city', 'street']}
          />

          <SelectInput
            label={'Часовой пояс, UTC'}
            name={'timezone'}
            formSelectors={formStateSelectors}
            options={timeZonesOptions}
          />

          <SelectInput
            label={'Тип распределителя'}
            name={'device_type'}
            formSelectors={formStateSelectors}
            options={devicesTypes}
          />

          <SelectInput
            label={<FormattedMessage id={'addressForm.unitType'}/>}
            name={'unit_type'}
            formSelectors={formStateSelectors}
            options={UNIT_TYPES}
          />

          <Input
            label={'ОДН, %'}
            name={'odn'}
            formSelectors={formStateSelectors}
            type={'number'}
          />

          {!userData.service_manager &&
            <SelectInput
              loading={!!lastGetServiceManagersRequest}
              label={'Сервис-менеджер'}
              name={'servicemanager'}
              formSelectors={formStateSelectors}
              options={[{
                value: 0,
                label: 'Не задан'
              }, ...serviceManagers]}
            />
          }

          <div style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '8px'
          }}>
            <input
              className="checkbox"
              type="checkbox"
              id="checkbox-id"
              name="auto_report"
              style={{
                cursor: 'pointer',
              }}
              value={autoReport ?? false}
              onClick={(event) => {
                setFormValue('auto_report')(event.target.checked)
              }}
            />

            <span style={{
              fontSize: '14px',
            }}>Автосбор показаний</span>
          </div>
        </div>

        <div className="mkd-from__btns">
          <button
            className="btn"
            onClick={handleSubmit}
            disabled={!isValidForm}
          >
            <FormattedMessage id={'app.save'}/>
          </button>

          {isNew || userData.service_manager || editId
            ? null
            : (
              <button
                className='btn btn_red'
                onClick={toggleDeleteModal}
              >
                <FormattedMessage id={'app.delete'}/>
              </button>
            )
          }
        </div>
      </Loader>

      <DefaultModal
        loading={isDeleting}
        isOpen={isOpenDeleteModal}
        toggleModal={toggleDeleteModal}
        agreeBtnConfig={{
          className: 'btn btn_red',
          text: <FormattedMessage id={'app.delete'}/>
        }}
        onAgree={() => onDelete(dataId)}
        onDisagree={toggleDeleteModal}
        headerTitle={'Удаление МКД'}
      />
    </div>
  )
}

export default MKDForm
