import ValidationsWrapper from "./ValidationsWrapper";

const Input = (
  {
    label,
    placeholder = '',
    name,
    formSelectors,
    type = 'text',
    valueFormatter = (value) => value,
    withChangeValue,
    disabled = false,
    additionalValidationMsg = '',
    groupClass = '',
    isShowValidMsg = true,
    attr ="newReportName",
    additionalProps = {}
  }) => {
  const value = formSelectors.useFormDataValue(name) ?? ''
  const setFieldData = formSelectors.useSetFormDataValue(name)
  const validations = formSelectors.useFormValueValidation(name)
  const fullValidationMsgs = additionalValidationMsg
    ? [...validations, additionalValidationMsg]
    : validations

  const onChange = (e) => {
    if (disabled) return

    const {value} = e.target

    if (withChangeValue) {
      withChangeValue(value)
    }

    setFieldData(valueFormatter(value))
  }

  return (
    <div className={`form-group ${groupClass}`}>
      <label>{label}</label>

      <ValidationsWrapper
        validationMsgs={fullValidationMsgs}
        isShowValidMsg={isShowValidMsg}
      >
        <input
          name={name}
          value={value ?? ''}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          onWheel={(e) => {
            if (type === 'number') {
              e.target.blur()
            }
          }}
          autoComplete="off"
          disabled={disabled}
          data-cy={attr}
          {...additionalProps}
        />
      </ValidationsWrapper>
    </div>
  )
}

export default Input
