import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {requiredValidator} from "@/internal/validators/validationsRules";
import {requiredMsg} from "@/internal/validators/validationsMessages";

const enumeratorIndicationsForm = new FormModelFabric({
  initialData: {
    indications: ''
  },
  initialValidations: {
    indications: []
  },
  getValidators: () => {
    return {
      indications:  (value) => {
        return requiredValidator(value)
          ? []
          : [requiredMsg]
      },
    }
  }
})

export const enumeratorIndicationsFormController = enumeratorIndicationsForm.createSelectors();