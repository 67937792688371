import React, {useEffect, useRef, useState} from "react";
import {selectors} from "@/presentation/shared/ui/message";
import useWindowSize from "@/internal/custom_hooks/windowResizeHook";
import {mkdsEndpoint} from "@/data/api_entities/mkds/mkds_endpoint";
import {FormattedMessage} from "react-intl";
import {NavLink, Route, Routes, useParams} from "react-router-dom";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import {PATHS} from "@/presentation/components/heatCalculationPage/components/MKDPage/const";
import LinkTab from "@/presentation/components/common/LinkTab";
import {MKDHelper} from "@/presentation/components/heatCalculationPage/helpers";
import Loader from "@/presentation/components/loader/Loader";
import OwnerApartmentPage
  from "@/presentation/components/pages/OwnerMainPage/components/OwnerApartmentPage/OwnerApartmentPage";
import ownerApartmentEndpoints from "@/data/api_entities/owner_apartment/owner_apartment.endpoints";

const OwnerApartmentsPage = ({data}) => {
  const {id} = useParams()
  const [loadingList, setLoadingList] = useState(false)
  const [paginationParams, setPaginationParams] = useState(null)
  const [isUploadNext, setIsUploadNExt] = useState(false)
  const [list, setList] = useState([]);
  const setMessage = selectors.useSetMessage()
  const getListRequestController = useRef();
  const windowSize = useWindowSize()
  const listLoaderRef = useRef(null)

  useEffect(() => {
    return () => {
      getListRequestController.current?.abort();
    }
  }, [])

  useEffect(() => {
    if (!id) return

    setLoadingList(true)

    const {promise} = ownerApartmentEndpoints.getMansion(id)

    promise
      .then(res => {
        if (Array.isArray(res)) {
          setList(res)
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось загрузить данные квартиры',
            type: 'error',
            count: 3000
          })
        }
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось загрузить данные квартиры',
          type: 'error',
          count: 3000
        })
      })
      .finally(() => setLoadingList(false))
  }, [id])

  return (
    <>
      <div className={'apartments-page'}>
        <ArrowBack
          pathOnVisible={PATHS.apartments}
        />

        <div className="apartments-page__title-wrapper">
          <h2 className="title">Квартиры</h2>
        </div>

        <Loader
          loading={loadingList}
          styles={{
            wrapper: (base) => ({
              ...base,
              width: '100%',
              overflow: 'auto',
              minHeight: '100px'
            }),
          }}
          propRef={listLoaderRef}
        >
          {list.length
            ? list.map(apartment => {
              return <LinkTab
                key={apartment.id}
                path={apartment?.id?.toString()}
                text={MKDHelper.getAddressWithApartments(data, apartment.kv)}
              />
            })
            : <p className={'apartments-page__empty-list'}>
              Нет квартир
            </p>
          }
        </Loader>
      </div>

      <Routes>
        <Route path={':id/*'} element={<OwnerApartmentPage mkdData={data} apartments={list}/>}/>
      </Routes>
    </>
  )
}

export default OwnerApartmentsPage;
