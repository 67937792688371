import {useEffect} from "react"
import {USER_DATA_TABS_LIST} from "./const"
import {Route, Routes} from 'react-router-dom'
import LinkTab from "@/presentation/components/common/LinkTab"
import {phoneFormatter} from "@/internal/formatters/formatters";
import {userEndpoint} from "@/data/api_entities/user/user_endpoint";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import {CHANGE_EMAIL_PATH, CHANGE_ENTITY_PATH, CHANGE_PASSWORD_PATH, MY_DETAILS_PATH} from "../const";
import {selectors} from "@/presentation/shared/ui/message";
import {userDataSelectors} from "@/internal/lib/storeModels/models/user/userDataModel";
import Input from "@/presentation/components/common/formComponents/Input";
import {userDataFormSelectors} from "@/internal/lib/storeModels/models/user/forms/userDataFormModel";
import MaskInput from "@/presentation/components/common/formComponents/MaskInput";
import ChangeEmail from "./components/ChangeEmail";
import ChangePassword from "./components/ChangePassword";
import ChangeEntity from "./components/ChangeEntity";
import {FormattedMessage} from "react-intl";

const UserData = () => {
  const isValidForm = userDataFormSelectors.useIsFormValid()
  const setInitData = userDataFormSelectors.useSetInitFormData()
  const setOnValidSubmit = userDataFormSelectors.useSetOnValidSubmit()
  const updateUserData = userDataSelectors.userData.useUpdateUserData()
  const onClickSubmit = userDataFormSelectors.useOnClickSubmit()
  const userData = userDataSelectors.userData.useValue()
  const setMessage = selectors.useSetMessage()

  const isService = !!userData.service_manager;
  const isOwner = !!userData.owner_apartment;

  useEffect(() => {
    setOnValidSubmit(onValidSubmit)
  }, [])

  useEffect(() => {
    setInitData({
      phone: userData.phone,
      login: userData.login
    })
  }, [userData])

  const links = USER_DATA_TABS_LIST.filter(linkData => {
    if (isOwner) {
      return linkData.id !== 3
    }
    return true
  }).map(({id, textId, path}, i) => {
    const email = userData.email || ''

    return (
      <LinkTab
        key={id}
        textId={textId}
        subText={i === 0 ? email : null}
        path={path}
      />
    )
  })

  const onValidSubmit = (data) => {
    userEndpoint.setUserData(data)
      .then(() => {
        updateUserData()

        setMessage({
          header:  <FormattedMessage id={'requests.detailsChanges'} />,
          message: <FormattedMessage id={'requests.detailsChangesSuccess'} />,
          count: 3000
        })
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'} />,
          message: <FormattedMessage id={'requests.detailsChangesError'} />,
          type: 'error',
          count: 3000
        })
      })
  }

  const handleSubmit = () => {
    if (!isValidForm) return

    onClickSubmit()
  }

  return (
    <>
      <div className='user-page__column user-page__column_user-data'>
        <ArrowBack
          pathOnVisible={MY_DETAILS_PATH}
        />

        <h2 className="title">
          <FormattedMessage id={'userPage.myData'}/>
        </h2>

        <div className="user-page__links">
          {links}
        </div>

        <div className="user-page__business-account">
          {!isOwner &&
            <>
              <h5>
                <FormattedMessage id={'userPage.personalAccount'}/>
              </h5>

              <div className="user-page__account-number">
                <span>{userData.account_number || '-'}</span>
              </div>

            </>
          }

          <div className="form-fields">
            <Input
              label={<FormattedMessage id={'userPage.howCanICallU'}/>}
              name="login"
              formSelectors={userDataFormSelectors}
            />

            {!isOwner && <MaskInput
              label={<FormattedMessage id={'userPage.tellNotReq'}/>}
              mask="+7 (999) 999-99-99"
              type="tel"
              name='phone'
              formSelectors={userDataFormSelectors}
              valueFormatter={phoneFormatter}
            />}

          </div>

          <button
            className="btn btn_transparent-bg"
            disabled={!isValidForm}
            onClick={handleSubmit}
          >
            <FormattedMessage id={'app.save'}/>
          </button>
        </div>
      </div>

      <Routes>
        <Route path={CHANGE_EMAIL_PATH} element={<ChangeEmail />}/>

        <Route path={CHANGE_PASSWORD_PATH} element={<ChangePassword />}/>

        {!isOwner && <Route path={CHANGE_ENTITY_PATH} element={<ChangeEntity />} />}
      </Routes>
    </>
  )
}

export default UserData
