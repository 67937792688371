import {useParams} from "react-router-dom";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import React, {useEffect, useRef, useState} from "react";
import {enumeratorEndpoint} from "@/data/api_entities/enumerator/enumartaor_endpoint";
import {FormattedMessage} from "react-intl";
import Loader from "@/presentation/components/loader/Loader";
import Input from "@/presentation/components/common/formComponents/Input";
import {
  enumeratorIndicationsFormController
} from "@/presentation/components/pages/OwnerMainPage/components/OwnerEnumerator/form.controller";
import {selectors} from "@/presentation/shared/ui/message";

const OwnerEnumerator = () => {
  const [enumeratorData, setEnumeratorData] = useState(null)
  const [loading, setLoading] = useState(true)
  const getDataRequestController = useRef()
  const {id} = useParams()
  const setMessage = selectors.useSetMessage()

  useEffect(() => {
    return () => {
      getDataRequestController.current?.abort()
    }
  }, [])

  useEffect(() => {
    if (id) {
      setLoading(true)
      getDataRequestController.current?.abort()

      const {promise, controller} = enumeratorEndpoint.getById(id)
      getDataRequestController.current = controller
      promise
        .then(res => {
          if ('id' in res) {
            setEnumeratorData(res)
          } else {
            setMessage({
              header: <FormattedMessage id={'requests.error'}/>,
              message: 'Не удалось получить данные ОП',
              type: 'error',
              count: 3000
            })
          }
          setLoading(false)
        })
        .catch(err => {
          if (err.name === 'AbortError') return
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось получить данные ОП',
            type: 'error',
            count: 3000
          })
          setLoading(false)
        })
    }
  }, [ id])

  return (
    <div className={'enumerator-form'}>
      <ArrowBack
        pathOnVisible={id}
      />

      <Loader loading={loading}>
        <div className="enumerator-form__title-wrapper">
          <h2 className="title">
            Показания ОП
          </h2>
        </div>
        <div className="form-fields">
          <Input
            formSelectors={enumeratorIndicationsFormController}
            name={'indications'}
            label={'Показания прибора'}
            valueFormatter={(value) => !!value.length ? +value : value}
            type={'number'}
          />
        </div>

        <div className="enumerator-form__btns">
          <button
            className="btn"
            // onClick={handleSubmit}
            // disabled={!isValidForm}
          >
            <FormattedMessage id={'app.save'}/>
          </button>
        </div>
      </Loader>
    </div>
  )
}

export default OwnerEnumerator;
