import { Delete, Edit} from '@mui/icons-material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import HoveredPopover from "@/presentation/components/common/HoveredPopover";
import {useState} from "react";
import FindOwner
  from "@/presentation/components/heatCalculationPage/components/MKDPage/components/ApartmentOwnerPage/components/FindOwner";
import Loader from "@/presentation/components/loader/Loader";
import DefaultModal from "@/presentation/components/common/modals/DefaultModal";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import {FormattedMessage} from "react-intl";
import RegeneratePassword
  from "@/presentation/components/heatCalculationPage/components/MKDPage/components/ApartmentOwnerPage/components/RegeneratePassword";

const OwnerCard = ({owner, onOwnerChange, selectedApartment, onRegisterOwner, onDelete}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [newOwner, setNewOwner] = useState(null)
  const [isChanging, setIsChanging] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isRegenerating, setIsRegenerating] = useState(false)

  const [openDeleteModal, toggleDeleteModal] = useToggle();

  const handleEnableEdit = () => setIsEdit(true)
  const handleCancelEdit = () => {
    setIsEdit(false)
    setNewOwner(null)
  }

  const handleDeleteOwner = async () => {
    try {
      setIsDeleting(true)
      await onDelete(owner.id);
    } catch (e) {
      console.log(e)
    } finally {
      toggleDeleteModal()
      setIsDeleting(false)
    }
  }

  const handleSelectNewOwner = (option) => {
    setNewOwner(option.value)
  }

  const handleChangeOwner = async () => {
    try {
      setIsChanging(true)
      if (onOwnerChange) {
        await onOwnerChange(newOwner)
      }
      setIsEdit(false)
    } catch (e) {
      console.log(e)
    } finally {
      setIsChanging(false)
    }
  }

  const handleToggleDeleteModal = () => {
    if (isDeleting) return;
    toggleDeleteModal();
  }

  const handleRegisterOwner = async (data) => {
    await onRegisterOwner(data)
    setIsEdit(false)
  }

  const handleLoadingRegeneratePassword = (value) => {
    setIsRegenerating(value)
  }

  return (
    <div className={'owner-card'}>
      <Loader loading={isChanging || isRegenerating}>
        <div className="owner-card__current-owner">
          <div className={'owner-card__owner-info'}>
            <span>{owner.login}</span>
            <span>{owner.email}</span>
          </div>

          <div className="owner-card__controls">
            {isEdit
              ? <HoveredPopover key={'edit'} text={'Отменить изменения'}>
                <button onClick={handleCancelEdit} className={'owner-card__cancel'}><CloseOutlinedIcon /></button>
              </HoveredPopover>
              : <>
                <HoveredPopover key={'delete'}  text={'Изменить собственника'}>
                  <button onClick={handleEnableEdit} className={'owner-card__edit'}>
                    <Edit />
                  </button>
                </HoveredPopover>

                <HoveredPopover key={'cancel'} text={'Отвязать собственника'}>
                  <button onClick={toggleDeleteModal} className={'owner-card__delete'}>
                    <Delete />
                  </button>
                </HoveredPopover>
              </>
            }
          </div>
        </div>

        {!isEdit && (
          <RegeneratePassword ownerId={owner?.id} onLoading={handleLoadingRegeneratePassword}/>
        )}

        {isEdit && <div className={'owner-card__add-new'}>
          <FindOwner onSelect={handleSelectNewOwner} onRegisterNewOwner={handleRegisterOwner}/>
          <button onClick={handleChangeOwner} className={'btn'} disabled={!newOwner || isChanging || newOwner?.id === owner?.id}>Изменить</button>
        </div>}
      </Loader>

      <DefaultModal
        isOpen={openDeleteModal}
        toggleModal={handleToggleDeleteModal}
        headerTitle={'Отвязка собственника'}
        bodyText={<>Вы действительно хотите отвязать <b>{owner.email}</b> от квартиры {selectedApartment?.kv ?? ''}?</>}
        loading={isDeleting}
        agreeBtnConfig={{
          className: 'btn',
          text: <FormattedMessage id={'app.proceed'}/>,
          disabled: isDeleting
        }}
        onAgree={handleDeleteOwner}
        onDisagree={handleToggleDeleteModal}
      />
    </div>
  )
}

export default OwnerCard;
